import { cn } from "~/utils/cn";

type sectionProps = {
  children: React.ReactNode;
  id?: string;
  className?: string;
  maxWidth?: boolean;
};

export function Section({
  children,
  id,
  className,
  maxWidth = false,
}: sectionProps) {
  return (
    <section
      className={cn(
        "relative mx-auto",
        maxWidth ? `` : "max-w-[90rem]",
        className
      )}
      id={id}
    >
      {children}
    </section>
  );
}
